import algosdk, { Transaction } from 'algosdk'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import { Zero } from '@/constants'
import {
  CALL_APP,
  GlobalKeys,
  LobalKeys,
  GLOBAL_STATE,
  LOCAL_STATE,
  VESTING_GLOBAL,
  VestingGlobalKeys,
} from './constants'
import { AlgoClient } from '@/algo-client'
import { PoolModel } from '@/models/pool-model'
import { bigNumberHelper } from '@/helper/bignumber-helper'
import { get } from 'lodash-es'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { buildState, checkOptInASAStatus, readGlobalState, readLocalState, signTransactions } from './utils'
export interface State {
  key?: string
  value?: {
    byte?: string
    type?: number
    uint: number
  }
  name?: string
}
export type BuildGlobalStates = {
  [id in GlobalKeys]: State
}

export type BuildLobalStates = {
  [id in LobalKeys]: State
}
export type BuildVestingGlobalStates = {
  [id in VestingGlobalKeys]: State
}

export class BlockChainHandler {
  appID? = 0
  appAccount? = ''
  tradeTokenID? = 0
  idoTokenID? = 0
  tokenDecimals = 6
  tradeDecimals = 6
  vestingID = 0

  // globalState = {} as BuildGlobalStates

  _startTime = 0
  _endTime = 0
  _totalInvestedAmount = Zero
  _totalAcceptedAmount = Zero
  _totalRefundAmount = Zero
  _tokensFund = Zero
  _tokenPrice = Zero
  _participants = 0
  _firstVestingTime = 0
  _firstVestingPercentage = Zero
  _linearVestingStart = 0
  _linearVestingEnd = 0
  _linearVestingPeriod = 0
  _maxClaimablePercentage = Zero

  _loaded = false
  _loadTask?: Promise<any>

  constructor(public pool: PoolModel) {
    this.appID = pool.appID
    this.appAccount = pool.appAccount
  }

  async getGlobalState(): Promise<BuildGlobalStates> {
    const res = await readGlobalState(AlgoClient, this.appID)
    return buildState(res, GLOBAL_STATE)
  }
  async getVestingGlobalState(): Promise<BuildVestingGlobalStates> {
    const res = await readGlobalState(AlgoClient, this.vestingID)
    return buildState(res, VESTING_GLOBAL)
  }

  async getLocalState(walletAddress: string): Promise<BuildLobalStates> {
    const res = await readLocalState(walletStore?.algodClient, walletAddress, this.appID)
    return buildState(res, LOCAL_STATE)
  }

  init(): Promise<any> {
    if (this._loaded) {
      return Promise.resolve()
    }
    if (this._loadTask) {
      return this._loadTask
    }

    this._loadTask = new Promise(async (resolve, reject) => {
      try {
        const {
          tradeId,
          vestingContract,
          noOfRegistrations,
          paused,
          claimEnabled,
          startTime,
          endTime,
          totalInvestedAmount,
          totalAcceptedAmount,
          totalRefundAmount,
          tokensFund,
          tokenId,
          tokenDecimals,
          tokenPrice,
          tradeDecimals,
          participants,
        } = await this.getGlobalState()
        this.tradeTokenID = get(tradeId, 'value.uint', 0)
        this.idoTokenID = get(tokenId, 'value.uint', 0)
        this.tokenDecimals = get(tokenDecimals, 'value.uint', 6)
        this.tradeDecimals = get(tradeDecimals, 'value.uint', 6)
        this._startTime = get(startTime, 'value.uint', 0)
        this._endTime = get(endTime, 'value.uint', 0)
        this._tokenPrice = bigNumberHelper.fromDecimals(get(tokenPrice, 'value.uint', 0), this.tradeDecimals)
        this._participants = get(participants, 'value.uint', 0)
        this.vestingID = get(vestingContract, 'value.uint', 0)
        this._totalInvestedAmount = bigNumberHelper.fromDecimals(
          get(totalInvestedAmount, 'value.uint', 0),
          this.tradeDecimals
        )
        this._totalRefundAmount = bigNumberHelper.fromDecimals(
          get(totalRefundAmount, 'value.uint', 0),
          this.tradeDecimals
        )
        this._totalAcceptedAmount = bigNumberHelper.fromDecimals(
          get(totalAcceptedAmount, 'value.uint', 0),
          this.tradeDecimals
        )
        this._tokensFund = bigNumberHelper.fromDecimals(get(tokensFund, 'value.uint', 0), this.tokenDecimals)

        this._loaded = true
        resolve(null)
      } catch (error) {
        reject(error)
        this._loadTask = undefined
      }
    })
    return this._loadTask
  }

  async loadVestingInfo() {
    try {
      const {
        fixedswapId,
        firstVestingTime,
        firstVestingPercentage,
        linearVestingStart,
        linearVestingEnd,
        linearVestingPeriod,
        maxClaimablePercentage,
        debug1,
        debug2,
        debug3,
      } = await this.getVestingGlobalState()
      this._firstVestingPercentage = bigNumberHelper.fromDecimals(get(firstVestingPercentage, 'value.uint', 0))
      this._maxClaimablePercentage = bigNumberHelper.fromDecimals(get(maxClaimablePercentage, 'value.uint', 0))
      this._firstVestingTime = get(firstVestingTime, 'value.uint', 0)
      this._linearVestingStart = get(linearVestingStart, 'value.uint', 0)
      this._linearVestingEnd = get(linearVestingEnd, 'value.uint', 0)
      this._linearVestingPeriod = get(linearVestingPeriod, 'value.uint', 0)
    } catch (e) {
      snackController.commonError(e)
    }
  }

  get vestingInfo() {
    return {
      firstVestingTime: this._firstVestingTime,
      firstVestingPercentage: this._firstVestingPercentage,
      linearVestingStart: this._linearVestingStart,
      linearVestingEnd: this._linearVestingEnd,
      linearVestingPeriod: this._linearVestingPeriod,
      maxClaimablePercentage: this._maxClaimablePercentage,
    }
  }

  async fetchpoolInfo() {
    try {
      const {
        vestingContract,
        noOfRegistrations,
        paused,
        claimEnabled,
        startTime,
        endTime,
        totalInvestedAmount,
        totalAcceptedAmount,
        totalRefundAmount,
        tokensFund,
        tokenId,
        tokenDecimals,
        tokenPrice,
        tradeDecimals,
        participants,
      } = await this.getGlobalState()
      this._tokensFund = bigNumberHelper.fromDecimals(get(tokensFund, 'value.uint', 0), this.tokenDecimals)
      this._participants = get(participants, 'value.uint', 0)
      this._totalInvestedAmount = bigNumberHelper.fromDecimals(
        get(totalInvestedAmount, 'value.uint', 0),
        this.tradeDecimals
      )
      this._totalRefundAmount = bigNumberHelper.fromDecimals(
        get(totalRefundAmount, 'value.uint', 0),
        this.tradeDecimals
      )
    } catch (e) {
      console.log('fetchpoolInfo error')
    }
  }

  async fetchUserContrain(account) {
    try {
      const { investedAmount, acceptedAmount, receivedRefundAmount, isRefund, claimedAmount } =
        await this.getLocalState(account)
      return {
        claimedAmount: bigNumberHelper.fromDecimals(get(claimedAmount, 'value.uint', 0), this.tokenDecimals),
        acceptedAmount: bigNumberHelper.fromDecimals(get(acceptedAmount, 'value.uint', 0), this.tradeDecimals),
        investedAmount: bigNumberHelper.fromDecimals(get(investedAmount, 'value.uint', false), this.tradeDecimals),
        receivedRefundAmount: bigNumberHelper.fromDecimals(
          get(receivedRefundAmount, 'value.uint', 0),
          this.tradeDecimals
        ),
        isRefund: get(isRefund, 'value.uint', 0) > 0 ? true : false,
      }
    } catch (e) {
      console.log('Get user contrain fail')
    }
  }

  get poolInfo() {
    return {
      tokenDecimals: this.tokenDecimals,
      tradeDecimals: this.tradeDecimals,
      startTime: this._startTime,
      endTime: this._endTime,
      participants: this._participants,
      tokenPrice: this._tokenPrice,
      totalInvestedAmount: this._totalInvestedAmount,
      totalRefundAmount: this._totalRefundAmount,
      totalAcceptedAmount: this._totalAcceptedAmount,
      tokensFund: this._tokensFund,
      idoTokenID: this.idoTokenID,
      tradeTokenID: this.tradeTokenID,
      vestingContract: this.vestingID,
    }
  }

  async sendOptIn(walletAddress: string) {
    const params = await AlgoClient.getTransactionParams().do()
    if (params && this.appID) {
      const txn = algosdk.makeApplicationOptInTxn(walletAddress, params, this.appID)
      const signedTxn = await signTransactions(walletStore.selectedWallet, txn)
      const { txId } = await AlgoClient.sendRawTransaction(signedTxn).do()
      const res = await algosdk.waitForConfirmation(AlgoClient, txId, 3)
    }
  }

  async getUserTradeTokenAmount(walletAddress: string) {
    const accountInfo = (await AlgoClient.accountInformation(walletAddress).do()) as any
    if (this.tradeTokenID && accountInfo.assets) {
      const tradeTokenInfo = accountInfo.assets.filter((item) => item['asset-id'] === this.tradeTokenID)[0]
      if (tradeTokenInfo?.amount) {
        return bigNumberHelper.fromDecimals(tradeTokenInfo.amount)
      }
    }
    return Zero
  }

  async optInASA(walletAddress: string, assetID: number) {
    const params = await AlgoClient.getTransactionParams().do()
    if (params && assetID) {
      const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        suggestedParams: { ...params },
        from: walletAddress,
        amount: 0,
        to: walletAddress,
        assetIndex: assetID,
      })
      const signedTxns = await signTransactions(walletStore.selectedWallet, [txn])
      const { txId } = await AlgoClient.sendRawTransaction(signedTxns).do()
      const res = await algosdk.waitForConfirmation(AlgoClient, txId, 3)
      return txId
    }
  }

  getStateByKey(states: State[], key: string): State {
    const keyBuffer = Buffer.from(key)
    const keyBase64 = keyBuffer.toString('base64')
    const globalStateObject = states.find((item: any) => item.key === keyBase64)
    return { ...globalStateObject, name: key }
  }

  async buy(walletAddress: string, amount: FixedNumber | string) {
    const params = await walletStore?.algodClient?.getTransactionParams().do()
    if (params && this.appID && this.appAccount && this.tradeTokenID) {
      const txn1 = algosdk.makeApplicationNoOpTxnFromObject({
        suggestedParams: { ...params },
        from: walletAddress,
        appIndex: this.appID,
        appArgs: [new Uint8Array(Buffer.from(CALL_APP.Buy))],
      })

      const txn2 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
        from: walletAddress,
        to: this.appAccount,
        assetIndex: this.tradeTokenID,
        amount: bigNumberHelper.toDecimalBigNumber(amount),
        suggestedParams: { ...params },
      })
      const signedTxns = await signTransactions(walletStore.selectedWallet, [txn1, txn2])
      const { txId } = await AlgoClient.sendRawTransaction(signedTxns).do()
      const res = await algosdk.waitForConfirmation(AlgoClient, txId, 3)
    }
  }

  async claim(walletAddress: string): Promise<string> {
    const params = await walletStore?.algodClient?.getTransactionParams().do()
    const vestingAppID = this.vestingID
    const txns: Transaction[] = []
    const vestingGlobalState = await readGlobalState(AlgoClient, vestingAppID)
    const maxClaimablePercentage = this.getStateByKey(vestingGlobalState, VESTING_GLOBAL.maxClaimablePercentage)

    const globalState = await readGlobalState(AlgoClient, this.appID)
    const localState = await readLocalState(AlgoClient, walletAddress, this.appID)
    const claimEnable = this.getStateByKey(globalState, GLOBAL_STATE.claimEnabled)

    algosdk.makeAssetCreateTxnWithSuggestedParamsFromObject

    if (params && this.appID && this.appAccount && this.idoTokenID && this.vestingID) {
      if (get(maxClaimablePercentage, 'value.uint') < BigInt(bigNumberHelper.toDecimalString('100', 6))) {
        const vestingTxn = algosdk.makeApplicationNoOpTxnFromObject({
          suggestedParams: { ...params },
          appIndex: this.vestingID,
          from: walletAddress,
          appArgs: [new Uint8Array(Buffer.from(CALL_APP.Claim))],
          foreignAssets: [this.idoTokenID],
        })
        txns.push(vestingTxn)
      }
      const isOptInASA = await checkOptInASAStatus(walletAddress, this.idoTokenID)
      if (!isOptInASA) {
        const optInIDOTokenTxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
          suggestedParams: { ...params },
          from: walletAddress,
          amount: 0,
          to: walletAddress,
          assetIndex: this.idoTokenID,
        })
        txns.push(optInIDOTokenTxn)
      }
      const claimTxn = algosdk.makeApplicationNoOpTxnFromObject({
        suggestedParams: { ...params, fee: 2000, flatFee: true },
        appIndex: this.appID,
        from: walletAddress,
        appArgs: [new Uint8Array(Buffer.from(CALL_APP.Claim))],
        foreignAssets: [this.idoTokenID],
        foreignApps: [this.vestingID],
      })
      txns.push(claimTxn)
    }
    const signedTxns = await signTransactions(walletStore.selectedWallet, txns)
    const { txId } = await AlgoClient.sendRawTransaction(signedTxns).do()
    const res = await algosdk.waitForConfirmation(AlgoClient, txId, 3)
    return txId || ''
  }

  async refund(walletAddress: string): Promise<string> {
    const params = await walletStore?.algodClient?.getTransactionParams().do()
    if (params && this.appID && this.appAccount && this.tradeTokenID) {
      const txn = algosdk.makeApplicationNoOpTxnFromObject({
        suggestedParams: { ...params, fee: 2000, flatFee: true },
        appIndex: this.appID,
        from: walletAddress,
        appArgs: [new Uint8Array(Buffer.from(CALL_APP.Refund))],
        foreignAssets: [this.tradeTokenID],
      })
      const signedTxns = await signTransactions(walletStore.selectedWallet, [txn])
      const { txId } = await AlgoClient.sendRawTransaction(signedTxns).do()
      const res = await algosdk.waitForConfirmation(AlgoClient, txId, 3)
      return txId
    }
    return ''
  }
}
