import { PoolModel } from '@/models/pool-model'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { Subject, timer } from 'rxjs'
import { takeUntil, takeWhile } from 'rxjs/operators'
import { asyncAction } from 'mobx-utils'
import moment from 'moment'
import { BlockChainHandler } from '@/blockchainHandler'
import { Zero } from '@/constants'
import { promiseHelper } from '@/helper/promise-helper'
import { random } from 'lodash'

export interface PoolState {
  targetTime: any
  transformStatusText: 'OPEN IN' | 'UPCOMING' | 'CLOSE IN' | 'OPEN IN' | 'END IN' | 'ENDED' | ''
  status:
    | 'WHITELIST SOON'
    | 'UPCOMING'
    | 'WHITELIST OPEN'
    | 'DEPOSIT SOON'
    | 'DEPOSIT OPEN'
    | 'PICKING LOTTERY WINNERS'
    | 'LOTTERY WINNER'
    | 'END'
    | ''
  statusColor: 'primary' | 'grey-lighten' | 'error' | 'violet-lighten' | 'blue-lighten' | ''
  countdownTargetDate: string
  countdownText: string
  countdownSeconds: number
  countdownMinutes: number
  countdownHours: number
  countdownDays: number
  isWhitelistStarted: boolean
  isWhitelistEnded: boolean
  isClaimStart: boolean
  isDepositStarted: boolean
  isDepositEnded: boolean
  isLotteryPublic: boolean
  isClaimEnd: boolean
}

export class PoolStore {
  private _diposers: IReactionDisposer[] = []
  private _unsubcrible = new Subject()

  @observable pool: PoolModel
  @observable poolState?: PoolState

  contract?: BlockChainHandler

  @observable tokenDecimals = 6
  @observable tradeDecimals = 6
  @observable startTime = 0
  @observable endTime = 0
  @observable participants = 0
  @observable tokenPrice = Zero
  @observable totalInvestedAmount = Zero
  @observable totalRefundAmount = Zero
  @observable totalAcceptedAmount = Zero
  @observable tokensFund = Zero
  @observable idoTokenID: number | undefined = 0
  @observable tradeTokenID: number | undefined = 0
  @observable vestingContract: number | undefined = 0;

  @asyncAction *updatePoolState() {
    this.poolState = yield this.getPoolState()
  }
  constructor(pool: PoolModel) {
    this.pool = pool
    this.updatePoolState()
    this.contract = new BlockChainHandler(pool)

    timer(1000, 1000)
      .pipe(
        takeUntil(this._unsubcrible),
        takeWhile(() => !this.poolState || !this.poolState.isClaimStart)
      )
      .subscribe(() => {
        this.updatePoolState()
      })

    timer(60000, 60000)
      .pipe(
        takeUntil(this._unsubcrible),
        takeWhile(() => !!this.pool?.appID && !!this.pool?.appAccount && !this.isFinalized)
      )
      .subscribe(async () => {
        await promiseHelper.delay(random(30) * 1000) //prevent cors error
        this.fetchPoolStore()
      })
    this.loadData()
  }

  @asyncAction *loadData() {
    try {
      if (this.contract && this.pool.appID) {
        for (let index = 0; index < 3; index++) {
          try {
            yield this.contract.init()
            break
          } catch (error) {
            console.log('error', error)
            if (index === 3) throw error
            else yield promiseHelper.delay(10000)
          }
        }
        this.syncState()
      }
    } catch (error) {
      console.error(this.pool.name, this.pool.tokenName, error)
    }
  }

  @action syncState() {
    if (this.contract) {
      const {
        tokenDecimals,
        tradeDecimals,
        startTime,
        endTime,
        participants,
        tokenPrice,
        totalInvestedAmount,
        totalRefundAmount,
        totalAcceptedAmount,
        tokensFund,
        idoTokenID,
        tradeTokenID,
        vestingContract,
      } = this.contract.poolInfo
      this.tokenDecimals = tokenDecimals
      this.tradeDecimals = tradeDecimals
      this.startTime = startTime
      this.endTime = endTime
      this.participants = participants
      this.tokenPrice = tokenPrice
      this.totalInvestedAmount = totalInvestedAmount
      this.totalRefundAmount = totalRefundAmount
      this.totalAcceptedAmount = totalAcceptedAmount
      this.tokensFund = tokensFund
      this.idoTokenID = idoTokenID
      this.tradeTokenID = tradeTokenID
      this.vestingContract = vestingContract
    }
  }

  @action.bound changeModel(model: PoolModel): void {
    this.pool = model
  }

  @asyncAction *fetchPoolStore() {
    try {
      if (this.contract) {
        yield this.contract.fetchpoolInfo()
        this.syncState()
      }
    } catch (error) {
      console.error(this.pool.name, this.pool.tokenName, error)
    }
  }

  @asyncAction *getPoolState() {
    /* eslint-disable */
    let status = ''
    let statusColor = ''
    let transformStatusText = ''
    const { whitelistStart, whitelistEnd, depositStart, depositEnd, lotteryPublic, claimStart, claimEnd } =
      this.pool?.data?.whitelistConfig || ({} as any)
    const poolStatus = this.pool?.status
    const currentTime = moment()
    const isWhitelistStarted = currentTime.isAfter(whitelistStart)
    const isWhitelistEnded = currentTime.isAfter(whitelistEnd)
    const isDepositStarted = currentTime.isAfter(depositStart)
    const isDepositEnded = currentTime.isAfter(depositEnd)
    const isLotteryPublic = currentTime.isAfter(lotteryPublic)
    const isClaimStart = currentTime.isAfter(claimStart)
    const isClaimEnd = currentTime.isAfter(claimEnd)
    let targetTime: any

    if (!isWhitelistStarted) {
      if (poolStatus === 'active') {
        status = 'WHITELIST SOON'
        statusColor = 'primary'
        transformStatusText = 'OPEN IN'
      } else {
        status = 'UPCOMING'
        statusColor = 'grey-lighten'
        transformStatusText = 'UPCOMING'
      }
      targetTime = moment(whitelistStart)
    } else if (!isWhitelistEnded) {
      const duration = moment.duration(moment(whitelistEnd).diff(moment(currentTime)))
      const hoursDuration = duration.asHours()
      statusColor = hoursDuration < 24 ? 'error' : 'primary'
      status = 'WHITELIST OPEN'
      targetTime = moment(whitelistEnd)
      transformStatusText = 'CLOSE IN'
    } else if (!isDepositStarted) {
      status = 'DEPOSIT SOON'
      targetTime = moment(depositStart)
      statusColor = 'violet-lighten'
      transformStatusText = 'OPEN IN'
    } else if (!isDepositEnded) {
      status = 'DEPOSIT OPEN'
      targetTime = moment(depositEnd)
      statusColor = 'violet-lighten'
      transformStatusText = 'END IN'
    } else if (!isLotteryPublic) {
      status = 'PICKING LOTTERY WINNERS'
      targetTime = moment(lotteryPublic)
      statusColor = 'violet-lighten'
      transformStatusText = 'END IN'
    } else if (!isClaimStart) {
      status = 'LOTTERY WINNER'
      targetTime = moment(claimStart)
      statusColor = 'blue-lighten'
      transformStatusText = 'END IN'
    } else if (!isClaimEnd) {
      status = 'CLAIM'
      targetTime = moment(claimEnd)
      statusColor = 'blue-lighten-2'
      transformStatusText = 'END IN'
    } else {
      status = 'ENDED'
      statusColor = 'grey-lighten'
      transformStatusText = 'ENDED'
    }

    let countdownTargetDate, countdownText, isTBACountDown
    if (!isWhitelistStarted) {
      countdownTargetDate = new Date(whitelistStart || '').getTime()
      countdownText = 'Whitelist opens in'
    } else if (!isWhitelistEnded) {
      countdownTargetDate = new Date(whitelistEnd || '').getTime()
      countdownText = 'Whitelist closes in'
    } else if (!isDepositStarted) {
      countdownText = 'Deposit opens in'
      countdownTargetDate = new Date(depositStart || '').getTime()
    } else if (!isDepositEnded) {
      countdownTargetDate = new Date(depositEnd || '').getTime()
      countdownText = 'Deposit closes in'
    } else if (!isLotteryPublic) {
      countdownTargetDate = new Date(lotteryPublic || '').getTime()
      countdownText = 'Lottery Result publics in'
    } else {
      countdownTargetDate = new Date(claimStart || '').getTime()
      countdownText = 'Lottery Result closes in'
    }

    const now = Math.trunc(new Date().getTime() / 1000)
    const dateInSeconds = Math.trunc(countdownTargetDate / 1000)
    const countdownDays = Math.trunc((dateInSeconds - now) / 60 / 60 / 24)
    const countdownHours = Math.trunc((dateInSeconds - now) / 60 / 60) % 24
    const countdownMinutes = Math.trunc((dateInSeconds - now) / 60) % 60
    const countdownSeconds = (dateInSeconds - now) % 60

    return {
      transformStatusText,
      targetTime,
      statusColor,
      status,
      countdownTargetDate,
      countdownText,
      countdownDays,
      countdownHours,
      countdownMinutes,
      countdownSeconds,
      isWhitelistStarted,
      isWhitelistEnded,
      isClaimStart,
      isDepositStarted,
      isDepositEnded,
      isLotteryPublic,
      isClaimEnd,
    }
  }

  @computed get isFinalized() {
    return this.poolState?.isClaimStart
  }

  @computed get valuePerTicket() {
    return this.pool?.data?.pricePerTicket || 100
  }

  destroy(): void {
    this._diposers.forEach((d) => d())
    this._unsubcrible.next()
    this._unsubcrible.complete()
  }
}
