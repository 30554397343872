import { IReactionDisposer, observable, computed } from 'mobx'
import { Subject } from 'rxjs'
import { asyncAction } from 'mobx-utils'
import { PoolStore } from './pool-store'
import { apiService } from '@/services/api-services'
import { PoolModel } from '@/models/pool-model'
import { sortBy } from 'lodash-es'

export class PoolsStore {
  private _diposers: IReactionDisposer[] = []
  private _unsubcrible = new Subject()

  _fetchedAll = false
  @observable cachedPoolStore: { [id: string]: PoolStore } = {}
  @observable allPools: PoolStore[] = [];

  @asyncAction *fetchPoolsIfNeed() {
    if (this._fetchedAll) return this.allPools
    else yield this.fetchPools()
    return this.allPools
  }

  @asyncAction *fetchPools() {
    const pools: PoolModel[] = yield apiService.pools.find({ _sort: 'index:DESC' })
    this.allPools = pools.map((p) => {
      let pool = this.cachedPoolStore[p.id ?? '']
      if (pool) {
        pool.changeModel(p)
      } else {
        pool = new PoolStore(p)
        this.cachedPoolStore[p.id ?? ''] = pool
      }
      return pool
    })
    this._fetchedAll = true
  }

  @asyncAction *findPool(unicodeName: string) {
    const res: any[] = yield apiService.pools.find({ unicodeName }, { _limit: 1 })
    const pool = res[0]
    if (pool) {
      let poolStore = this.cachedPoolStore[pool.id as any]
      if (poolStore) {
        poolStore.changeModel(pool)
      } else {
        poolStore = new PoolStore(pool)
        this.cachedPoolStore[pool.id as any] = poolStore
      }
      return poolStore
    }
    return null
  }

  @asyncAction *getPool(unicodeName: string) {
    const res: PoolModel[] = yield apiService.pools.find({ unicodeName }, { _limit: 1 }) || []
    if (res.length <= 0 || !res[0]) return null
    const pool = res[0]
    let poolStore = this.cachedPoolStore[pool.id as any]
    if (poolStore) {
      poolStore.changeModel(pool)
    } else {
      poolStore = new PoolStore(pool)
      this.cachedPoolStore[pool.id as any] = poolStore
    }
    return poolStore
  }

  @computed get validPools() {
    const valids = this.allPools.filter((p) => !p.pool?.data?.forceHide)
    return sortBy(valids, (x: PoolStore) => -(x.pool.index || 0))
  }

  destroy() {
    this._diposers.forEach((d) => d())
    this._unsubcrible.next()
    this._unsubcrible.complete()
  }
}

export const poolsStore = new PoolsStore()
